



















































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Watch } from 'vue-property-decorator'

import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import MentorMastersModule from '@/store/modules/mentor/masters'
import { formatDate, getCurrentMonth, openNewTab } from '@/utils/functions'
import {
  EducationMasterGroupResource,
  ManagerMasterGroupsMasterGroupIdMastersGetParams,
  MasterShortResource, UserShortResource,
} from '@/store/types'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import UserCard from '@/components/cards/UserCard.vue'
import ChartExercise from '@/components/charts/ChartExercise.vue'
import ChartLesson from '@/components/charts/ChartLesson.vue'
import Hearts from '@/components/Hearts.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import MasterEducationCard from '@/components/cards/MasterEducationCard.vue'

@Component({
  components: {
    Autocomplete,
    ButtonTextIcon,
    ChartExercise,
    ChartLesson,
    Hearts,
    MasterEducationCard,
    Select,
    TextInput,
    UserCard,
  },
})
export default class MentorMasters extends MasterGroupMixin {
  private mastersInGroup: MasterShortResource[] = []

  private get filter() {
    return MentorMastersModule.filter
  }

  private set filter(filter: ManagerMasterGroupsMasterGroupIdMastersGetParams) {
    MentorMastersModule.setFitler(filter)
    this.fetchMasterInGroup()
  }

  private get currentMentor () {
    return MentorMastersModule.currentChosenMentorId as number
  }

  private set currentMentor (value: number) {
    MentorMastersModule.setCurrentChosenMentorId(value)
    this.fetchMentorsMasterGroups()
  }

  private get mentorMasterGroups () {
    return MentorMastersModule.mentorMasterGroups.map(item => ({ name: item.title, value: item.id }))
  }

  private get currentMentorMasterGroup () {
    return MentorMastersModule.currentMentorMasterGroup
  }

  private get currentMentorMasterGroupId () {
    if (MentorMastersModule.currentMentorMasterGroup) {
      return MentorMastersModule.currentMentorMasterGroup?.id
    }
    return null
  }

  private set currentMentorMasterGroupId (value: number | null) {
    if (value) {
      const foundMasterGroup = MentorMastersModule.mentorMasterGroups.find(item => item.id === value)
      foundMasterGroup && MentorMastersModule.setCurrentMentorMasterGroup(foundMasterGroup)
    } else {
      MentorMastersModule.setCurrentMentorMasterGroup(null)
    }
  }

  private get mentorMonths () {
    if (this.currentMentorMasterGroup) {
      return this.currentMentorMasterGroup.months.map(item => ({
        name: formatDate(item.name, 'LLLL yyyy'),
        value: item.value,
      }))
    }
    return []
  }

  private get mentors () {
    return MentorMastersModule.mentors.map((item: UserShortResource) => ({ name: `${item.name} ${item.surname}`, value: item.id }))
  }

  private mounted () {
    this.fetchMentorsMasterGroups()
    this.$bus.$on('exportMastersList', this.handleExport)
  }

  private destroyed() {
    this.$bus.$off('exportMastersList', this.handleExport as any)
  }

  private handleFilter(field: keyof ManagerMasterGroupsMasterGroupIdMastersGetParams, value: any) {
    this.filter = {
      ...this.filter,
      [field]: value,
    }
  }

  private handleExport () {
    openNewTab(`/manager/master-groups/${this.currentMentorMasterGroupId}/masters/export`, this.filter)
  }

  @Bind
  @Debounce(300)
  private fetchMasterInGroup () {
    if (this.currentMentorMasterGroupId) {
      MentorMastersModule.fetchMasterGroupsSelect({
        masterGroupID: this.currentMentorMasterGroupId,
        params: this.filter,
      })
        .then(response => this.mastersInGroup = response)
        .catch(this.notifyError)
    }
  }

  @Bind
  @Debounce(300)
  private fetchMentorsMasterGroups () {
    MentorMastersModule.fetchMentorsMasterGroups()
      .then((response: EducationMasterGroupResource[] | undefined) => {
        if (response && response.length > 0) {
          if (this.currentMentorMasterGroup) {
            this.currentMentorMasterGroupId = this.currentMentorMasterGroup.id
          } else {
            this.currentMentorMasterGroupId = null
            this.mastersInGroup = []
          }
        } else {
          this.currentMentorMasterGroupId = null
          this.mastersInGroup = []
        }

        this.changeCurrentMentor(this.currentMentorMasterGroupId)
      })
      .catch(this.notifyError)
  }

  // Нужно для запроса мастеров при изменении наставника или группы
  private changeCurrentMentor(currentMentorMasterGroupId: number | null) {
    if (currentMentorMasterGroupId) {
      if (this.currentMentorMasterGroup) {
        const currentMonth = getCurrentMonth(this.currentMentorMasterGroup.months)
        if (currentMonth) {
          this.filter = {
            ...this.filter,
            monthId: currentMonth.value as number,
          }
        }
        this.$emit('onMasterGroupChange', currentMentorMasterGroupId)
      }
    }
  }

  private handleRowClick (masterData: MasterShortResource) {
    this.$router.push({
      name: 'manager.education.masters.item.lessons',
      params: {
        masterUUID: masterData.user.uuid,
      },
      query: {
        groupID: (this.currentMentorMasterGroupId as number).toString(),
        monthID: this.filter.monthId?.toString(),
      },
    })
  }

  private get headers () {
    return [
      { sortable: false, text: 'Пользователь', value: 'user', width: '25%' },
      { sortable: false, text: 'Жизни', value: 'hearts', width: '17%' },
      { sortable: false, text: 'Уроки', value: 'lessons', width: '20%' },
      { sortable: false, text: 'Домашняя работа', value: 'exercise', width: '33%' },
      { sortable: false, text: 'Сообщения', value: 'messages' },
    ]
  }

  @Watch('currentMentorMasterGroupId')
  watchMentorMasterGroup (value: number | null) {
    this.changeCurrentMentor(value)
  }
}
