import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import {
  EducationMasterGroupResource,
  ManagerMasterGroupsMasterGroupIdMastersGetParams,
  ManagerMasterGroupsMasterGroupIdMastersGetRequest,
  ManagerMasterGroupsSelectGetRequest,
  ManagerMentorsSelectGetParams,
  ManagerMentorsSelectGetRequest,
  UserShortResource,
} from '@/store/types'
import store from '@/store'

/**
 *  Модуль затрагивает мастеров и наставников в учебном процессе
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MentorMasters',
  namespaced: true,
  store,
})
class MentorMasters extends VuexModule {
  // ---------------------------- My Masters ---------------------------- >>
  // Todo: метод надо тут использовать, а не в mentor/groups. Да, это копия того метода будет, но иначе непонятно откуда что берется
  // + Это сейчас данные для запросов совпадают, а после может что-то измениться

  // ---------------------------- Mentors Masters ---------------------------- >>
  mentors: UserShortResource[] = []
  mentorMasterGroups: EducationMasterGroupResource[] = []
  currentChosenMentorId: number | null = null
  currentMentorMasterGroup: EducationMasterGroupResource | null = null
  filter: ManagerMasterGroupsMasterGroupIdMastersGetParams = {
    monthId: undefined,
    query: undefined,
  }

  @Mutation
  setFitler(payload: ManagerMasterGroupsMasterGroupIdMastersGetParams) {
    this.filter = Object.assign({}, payload)
  }

  @Mutation
  setMentors (payload: UserShortResource[]) {
    this.mentors = [...payload]
  }

  @Mutation
  setCurrentChosenMentorId (payload: number) {
    this.currentChosenMentorId = payload
  }

  @Mutation
  setMentorMasterGroups (payload: EducationMasterGroupResource[]) {
    this.mentorMasterGroups = [...payload]
  }

  @Mutation
  setCurrentMentorMasterGroup (payload: EducationMasterGroupResource | null) {
    this.currentMentorMasterGroup = payload
  }

  @Action({ rawError: true })
  async fetchMentors (params?: ManagerMentorsSelectGetParams) {
    const { data } = await ManagerMentorsSelectGetRequest(params)
    this.setMentors(data)
    if (data.length > 0) {
      this.setCurrentChosenMentorId(data[0].id)
    }
    return data
  }

  @Action({ rawError: true })
  async fetchMentorsMasterGroups () {
    if (this.currentChosenMentorId) {
      const { data } = await ManagerMasterGroupsSelectGetRequest({ mentorId: this.currentChosenMentorId })
      this.setMentorMasterGroups(data)
      if (data.length > 0 && !data.find(group => group.id === this.currentMentorMasterGroup?.id)) {
        this.setCurrentMentorMasterGroup(data[0])
      }
      return data
    }
  }

  @Action({ rawError: true })
  async fetchMasterGroupsSelect (payload: { masterGroupID: number, params?: ManagerMasterGroupsMasterGroupIdMastersGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersGetRequest(payload.masterGroupID, payload.params)
    return data
  }
}

const MentorMastersModule = getModule(MentorMasters)

export default MentorMastersModule
